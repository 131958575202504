// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/error/error.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/error/error.tsx");
  import.meta.hot.lastModified = "1709038524751.7402";
}
// REMIX HMR END

import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useNavigate } from "@remix-run/react";
const ErrorPage = () => {
  _s();
  const error = useRouteError();
  const [timeRemaining, setTimeRemaining] = useState(8);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        clearInterval(interval);
        navigate('/');
      }
    }, 1000);
    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
  }, [timeRemaining]);
  if (isRouteErrorResponse(error)) {
    return <div className="flex h-full flex-col p-4">
        <img alt="Logo.glanum" src="../../images/glanum2.png" className="w-fit" />
        <div className="flex h-full flex-col items-center justify-center gap-2">
          <h1 className="mt-5 text-[32px] font-bold text-glanum-red">
            Oops...
          </h1>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Un problème est survenu
          </h2>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Vous allez être redirigé dans {timeRemaining} secondes.
          </h2>
          {/* <div className="max-w-[80%] rounded-[10px] p-8 lg:shadow-card">
            <span>Message de l'erreur :</span>
            <div className="m-4 flex flex-col gap-2 border-2 border-slate-100 bg-slate-50 px-4 py-8">
              <p>
                {error.status} {error.statusText}
              </p>
              <p>The stack trace is:</p>
              <pre className="text-[10px]">{error.data}</pre>
            </div>
           </div> */}
        </div>
      </div>;
  } else if (error instanceof Error) {
    return <div className="flex h-full flex-col p-4">
        <img alt="Logo.glanum" src="../../images/glanum2.png" className="w-fit" />
        <div className="flex h-full flex-col items-center justify-center gap-2">
          <h1 className="mt-5 text-[32px] font-bold text-glanum-red">
            Oops...
          </h1>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Un problème est survenue
          </h2>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Vous allez être redirigé dans {timeRemaining} secondes.
          </h2>
          {/* <div className="max-w-[80%] rounded-[10px] p-8 lg:shadow-card">
            <span>Message de l'erreur :</span>
            <div className="m-4 flex flex-col gap-2 border-2 border-slate-100 bg-slate-50 px-4 py-8">
              <p>{error.message}</p>
              <p>The stack trace is:</p>
              <pre className="text-[10px]">{error.stack}</pre>
            </div>
           </div> */}
        </div>
      </div>;
  } else {
    return <div className="flex h-full flex-col p-4">
        <img alt="Logo.glanum" src="../../images/glanum2.png" className="w-fit" />
        <div className="flex h-full flex-col items-center justify-center gap-2">
          <h1 className="mt-5 text-[32px] font-bold text-glanum-red">
            Oops...
          </h1>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Une erreur inconnnue est survenue
          </h2>
          <h2 className="mt-5 text-[24px] font-bold text-glanum_text-dark">
            Vous allez être redirigé dans {timeRemaining} secondes.
          </h2>
        </div>
      </div>;
  }
};
_s(ErrorPage, "3l/eRl1p79sc/KqSKDqBOv/pceQ=", false, function () {
  return [useRouteError, useNavigate];
});
_c = ErrorPage;
export default ErrorPage;
var _c;
$RefreshReg$(_c, "ErrorPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;